import { AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';

import { Constants } from './shared/constants';
import { LocaleService } from './shared/services/locale.service';
import { TrustGuard } from './shared/services/trust-guard.service';
import { SplashScreenService } from './shared/splash-screen/splash-screen.service';
import { Utils } from './shared/utils';

export enum Theme {
  classic = 'classic',
  twilight = 'twilight',
  sunset = 'sunset',
  neutral = 'neutral',
  ocean = 'ocean',
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

/**
 * @class AppComponent Base component class
 * @see https://angular.io/guide/bootstrapping
 */
export class AppComponent implements AfterViewInit, OnInit {
  title = 'SaaS Login UI';
  originValidated$: Observable<boolean>;
  dataLoaded = false;
  /**
   * @constructor for AppComponent
   * @param {LocaleService} localeService, to determine the locale to use in the application
   */
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private localeService: LocaleService,
    private splashScreenService: SplashScreenService,
    private trustGuard: TrustGuard,
  ) {
    /** support for QTWeb */
    const pathname = window.location.pathname;
    if (Utils.unusualBrowser() && pathname !== Constants.ROOT_URI + Constants.LOGOUT_ROUTE
      && pathname !== Constants.ROOT_URI + Constants.ERROR_ROUTE) {
      // (qtweb) not yet supported to handle various themes, for now we default to classic theme styles
      document.body.setAttribute('style', 'background-image: -webkit-linear-gradient(left, #3e53a4 0%, #cf0989 100%)');
      return;
    }
    // chaget the theme if URL has a theme parameter
    const theme = Utils.getParam('theme');
    if (pathname === Constants.ROOT_URI + Constants.LOADING_ROUTE) {
      document.body.setAttribute('theme', 'none');
    } else if (theme in Theme) {
      document.body.setAttribute('theme', theme ? theme : 'classic');
    }
  }

  ngOnInit() {
    this.originValidated$ = this.trustGuard.originValidated;
  }

  ngAfterViewInit() {
    if (window.location.pathname !== Constants.ROOT_URI + Constants.LOGOUT_ROUTE) {
      this.splashScreenService.loaderObserver$.pipe((
        tap((dataLoaded) => {
          if (dataLoaded !== this.dataLoaded) {
            this.dataLoaded = dataLoaded;
            this.changeDetectorRef.detectChanges();
          }
        })
      )).subscribe();
    }
  }

}
